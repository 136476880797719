// import logo from './logo.svg';
import './App.css';
import { Login } from "./components/Pages/Auth/Login";
import { Register } from "./components/Pages/Auth/Register";
import HomeSearch from './components/Pages/Search/HomeSearch';
import PrivateRoutes from './utils/ProtectedRoutes';
import Subcodes from './components/Pages/subcodes/Subcodes';
import AddRules from './components/Pages/subcodes/AddRule';
import Users from './components/Pages/users/Users';
import UploadProducts from './components/Pages/Scraping/UploadProducts';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Import from './components/Pages/Import/ImportFile';
import Extract from './components/Pages/Import/ExtractData';
import Format from './components/Pages/Import/FormatData';
import Toast from './utils/toast';
import Vendors from './components/Pages/vendors/Vendors';
import Results from './components/Pages/Scraping/Results';
import ProductDetails from './components/Pages/Scraping/ProductDetails';
import Settings from './components/Pages/Scraping/Settings';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/home" element={<HomeSearch />} />
            <Route path="/subcodes" element={<Subcodes />} />
            <Route path="/add-rules" element={<AddRules />} />
            <Route path="/users" element={<Users />} />
            <Route path="/import" element={<Import />} />
            <Route path="/extract" element={<Extract />} />
            <Route path="/format" element={<Format />} />
            <Route path="/vendors" element={<Vendors />} />
            <Route path="/results" element={<Results />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/upload-products" element={<UploadProducts />} />
            <Route path="/product/:id" element={<ProductDetails />} />
          </Route>
        </Routes>
        <Toast />
      </div>
    </Router>
  );
}

export default App;
