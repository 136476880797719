import API from './api';

export const getAllUsers = (page, per_page) => {
    const auth = JSON.parse(localStorage.getItem('user'));
    const config = {
        headers: {
            'Authorization': `Bearer ${auth.access}`
        },
        params: {
            page: page,
        }
    };
    if (per_page) {
        config.params.page_size = per_page;
    } else {
        config.params.page_size = 10;
    }
    return API.get("user", config)
        .then(response => { 
            if (response.status == 200) {
                return response;
            } else {
                return Promise.reject(response);
            }
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

export const addUser = (data) => {
    const auth = JSON.parse(localStorage.getItem('user'));

    return API.post("user", data, {
        headers:{
            'Authorization': `Bearer ${auth.access}`
        }
    })
    .then(response => { 
        return response; 
    },
    error => { 
        return error; 
    })
}

export const updateUser = (data) => {
    const auth = JSON.parse(localStorage.getItem('user'));
    return API.patch("user/"+ data.id , data, {
        headers:{
            'Authorization': `Bearer ${auth.access}`
        }
    })
    .then(response => { 
        return response; 
    },
    error => { 
        return error.response.data; 
    })
}

export const deleteUser = (userId) => {
    const auth = JSON.parse(localStorage.getItem('user'));
    const config = {
        headers: {
            'Authorization': `Bearer ${auth.access}`
        },
    };
    return API.delete("user/"+ userId, {
        headers:{
            'Authorization': `Bearer ${auth.access}`
        }
    })
    .then(response => { 
        return response; 
    },
    error => { 
        return error.response.data; 
    })
}
